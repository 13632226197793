import { motion, useCycle } from 'framer-motion';

const ApprenticeshipBox = () => {
  const [color, cycleColor] = useCycle('#000', '#FFF');
  const [divState, cycleDiv] = useCycle('inActive', 'active');

  const divVariants = {
    inActive: { zIndex: 0 },
    active: { zIndex: 10 },
  };
  const lineVariants = {
    initial: { stroke: '#000' },
    animate: { stroke: color },
  };
  return (
    <motion.div
      variants={divVariants}
      animate={divState}
      whileTap={{ scale: 0.9 }}
      onTap={() => {
        cycleColor();
      }}
      onClick={() => cycleDiv()}
      className='absolute  cursor-pointer opacity-90 bg-textColor-main px-6 py-2 border rounded-lg h-32 xl:h-36  w-56  2xl:w-64  left-[15%]  2xl:left-[20%] top-10'
    >
      <div className='flex '>
        <div className='flex  '>
          <img
            className='z-10 object-contain'
            src='./images/users/one.png'
            alt='user'
          />
          <img
            className='z-20 -mx-2 object-contain'
            src='./images/users/two.png'
            alt='user'
          />
          <img
            className='z-30 object-contain'
            src='./images/users/three.png'
            alt='user'
          />
        </div>
        <div className='text-white text-sm ml-2'>
          <p>
            <span className='font-semibold'>10k+</span> Learner
          </p>
          <p>Engagements</p>
        </div>
      </div>
      <div className='mt-3 xl:mt-6 text-white text-lg  w-1/2'>
        <p>
          <span className='font-bold'>500+ </span>
          Live Apprenticeships
        </p>
      </div>
      {/*  lines */}
      <div className='relative hidden'>
        <div className='absolute -top-16 left-[110%] pointer-events-none'>
          <svg>
            <motion.rect x='3' y='0' width='120' height='8' fill='#BFEFFF' />
            <motion.line
              x1='0%'
              y1='4'
              x2='40%'
              y2='4'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
            <motion.rect x='115' y='6' width='8' height='82' fill='#BFEFFF' />
            <motion.line
              x1='118'
              y1='12'
              x2='118'
              y2='82'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default ApprenticeshipBox;

import { motion, useCycle } from 'framer-motion';

const RecruitersBox = () => {
  const [color, cycleColor] = useCycle('#000', '#FFF');
  const [divState, cycleDiv] = useCycle('inActive', 'active');

  const divVariants = {
    inActive: { zIndex: 0 },
    active: { zIndex: 10 },
  };
  const lineVariants = {
    initial: { stroke: '#000' },
    animate: { stroke: color },
  };
  return (
    <motion.div
      variants={divVariants}
      animate={divState}
      whileTap={{ scale: 0.9 }}
      onTap={() => {
        cycleColor();
      }}
      onClick={() => cycleDiv()}
      className='absolute cursor-pointer h-50vh w-50vw left-[70%] top-10 border bg-primary-lightest  rounded-3xl flex text-primary px-2 py-1'
    >
      <div className='bg-primary text-white text-sm font-bold rounded-full h-10 w-10 flex justify-center items-center'>
        500+
      </div>
      <p className='font-semibold text-sm ml-2 '>
        Recruiters
        <br /> Hiring
      </p>
      {/* lines */}
      <div>
        <div className='absolute top-1/2 -left-[90%] pointer-events-none hidden'>
          <svg>
            <motion.rect x='1' y='0' width='120' height='8' fill='#BFEFFF' />
            <motion.line
              x1='2'
              y1='4'
              x2='122'
              y2='4'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
            <motion.rect x='1' y='6' width='8' height='112' fill='#BFEFFF' />
            <motion.line
              x1='4'
              y1='2'
              x2='4'
              y2='112'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default RecruitersBox;

import ApprenticeshipBox from './ApprenticeshipBox';
import BelongScoreBox from './BelongScoreBox';
import CapstoneProjectBox from './CapstoneProjectBox';
import Institutes from './carouselSection/Institutes';
import RecruitersBox from './RecruitersBox';
import SeeHowBox from './SeeHowBox';
import UserImageBox from './UserImageBox';

const SectionOne = () => {
  return (
    <div className='relative grid grid-cols-12  lg:pt-8  '>
      <div className='grid  col-span-12 lg:col-span-5 '>
        <div>
          <p className='text-textColor-main font-semibold lg:text-4xl text-3xl lg:leading-relaxed mt-8 lg:mt-0'>
            Industry Experience Tailored To Your{' '}
            <span className='text-primary font-semibold	'>Curriculum</span>
          </p>
          <p className='lg:text-xl text-lg font-normal text-textColor-lighter mt-4 mb-6  lg:my-12 md:w-3/5'>
            Enabling your learner journey from skills To employability with
            industry-integrated experience platform
          </p>

          <a
            href='https://calendly.com/jeeva_belong_education/30min'
            rel='noreferrer noopener'
            target='_blank'
            className='text-white bg-primary font-semibold  px-6 py-3 rounded-lg  lg:text-xl  shadow'
          >
            Schedule Demo
          </a>
        </div>
      </div>
      <div
        className='hidden absolute h-screen lg:grid  w-[70%] left-1/3 top-0  col-span-12 lg:col-span-7 bg-contain bg-no-repeat bg-top '
        style={{
          backgroundImage:
            'url(https://res.cloudinary.com/belong/image/upload/v1668686822/university%20dashboard/map_kvqzon.png)',
        }}
      >
        <div className='relative'>
          <RecruitersBox />
          <ApprenticeshipBox />
          <SeeHowBox />
          <BelongScoreBox />
          <CapstoneProjectBox />
          {/* belong logo */}
          <div className='absolute z-20 top-[21%]  2xl:top-1/4 left-[52%] 2xl:left-[55%]'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
              alt='logo'
              className='hidden lg:block w-16'
            />
          </div>
          {/* single user image,below logo */}
          <UserImageBox />
        </div>
      </div>
      <div className='grid col-span-12 mt-10 lg:mt-24 '>
        <Institutes />
      </div>
    </div>
  );
};

export default SectionOne;

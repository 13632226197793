import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 7,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 5,
    partialVisibilityGutter: 40,
  },
};

const employersLogos = [
  {
    id: 1,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153104/landing/company-logo/Group_318_ml6o7p.png',
  },
  {
    id: 2,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153104/landing/company-logo/Group_346_inlyhv.png',
  },
  {
    id: 3,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153104/landing/company-logo/Group_311_kszeqn.png',
  },
  {
    id: 4,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153104/landing/company-logo/Group_344_wlkp1b.png',
  },
  {
    id: 5,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_317_dfnt7z.png',
  },
  {
    id: 6,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_331_u8aciq.png',
  },
  {
    id: 7,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_339_mizddi.png',
  },
  {
    id: 8,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_343_sqccuh.png',
  },
  {
    id: 9,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_312_f9k7dt.png',
  },
  {
    id: 10,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_316_hdnd3a.png',
  },
  {
    id: 11,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_313_zyehlw.png',
  },
  {
    id: 12,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_347_siy6ih.png',
  },
  {
    id: 13,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_342_eqzfls.png',
  },
  {
    id: 14,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_348_vf3lrj.png',
  },
  {
    id: 15,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_335_tcjjtu.png',
  },
  {
    id: 16,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_314_obsf5w.png',
  },
  {
    id: 17,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_340_cwz8xa.png',
  },
  {
    id: 18,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_341_ywl2qy.png',
  },
  {
    id: 19,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_332_s9ahg2.png',
  },
  {
    id: 20,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153102/landing/company-logo/Group_334_ujb2p8.png',
  },
  {
    id: 21,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_330_c2vgq5.png',
  },
  {
    id: 22,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_338_uryxbf.png',
  },
  {
    id: 23,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_345_eygxb4.png',
  },
  {
    id: 24,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_337_th2nrw.png',
  },
  {
    id: 25,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_336_elsimv.png',
  },
];
const SliderEmployers = ({ deviceType }) => {
  return (
<Carousel
additionalTransfrom={0}
arrows={false}
autoPlay
autoPlaySpeed={2000}
centerMode={false}
className=""
containerClass="container-with-dots"
customTransition="all 4s linear"
transitionDuration={2000}
dotListClass=""
draggable
focusOnSelect={false}
infinite
itemClass=""
keyBoardControl
minimumTouchDrag={80}
pauseOnHover
renderArrowsWhenDisabled={false}
renderButtonGroupOutside={false}
renderDotsOutside={false}
responsive={responsive}
rewind={false}
rewindWithAnimation={false}
rtl={false}
shouldResetAutoplay
showDots={false}
sliderClass=""
slidesToSlide={1}
swipeable
>
      {employersLogos.map(({ id, logo }) => {
        return (
          <div key={id} className='flex  justify-center mt-12  items-center '>
            <img src={logo} alt='company-logo' />
          </div>
        );
      })}
    </Carousel>
  );
};

export default SliderEmployers;

import React from 'react';
import classNames from 'classnames';

const TextAreaPart6: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <div>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          {' '}
          Skip the hiring queue and <br />
        </span>{' '}
        get placement with recruiters{' '}
      </p>
      <div className='py-6 flex lg:py-8 items-center'>
        <p
          className={classNames(
            'text-lg  px-4 py-1 lg:py-2 rounded-full',
            {
              'text-primary bg-primary-lightest': !active,
            },
            {
              'bg-primary text-white font-medium': active,
            }
          )}
        >
          Recruitment{' '}
        </p>
      </div>
      <p
        className={classNames(
          'md:w-[90%] text-lg',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        Plug-in our global our recruiter network with your Placement Portal{' '}
      </p>
    </div>
  );
};

export default TextAreaPart6;

import { useEffect, useRef, useState } from 'react';
import TextAreaPart1 from '../textAreas/TextAreaPart1';
import { motion, useScroll } from 'framer-motion';
import classNames from 'classnames';

const Part1 = () => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });
  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      // console.log('Page scroll 1: ', latest);
      setActive(latest > 0);
    });
  }, [scrollYProgress]);

  return (
    <>
      <div
        ref={ref}
        className='relative grid grid-cols-12 gap-1 pb-20 lg:pb-40'
      >
        <div className='col-span-12 md:col-span-6 '>
          <TextAreaPart1 active={active} />
        </div>
        <div className='col-span-12 md:col-start-8  md:col-span-5  mt-6 md:mt-0'>
          <img
            src='./images/s3images/part1pic.png'
            alt='pic'
            className='lg:w-3/4'
          />
        </div>
        <div
          className={classNames(
            'hidden z-10 absolute left-[48%] -top-4  w-16 h-16 rounded-full md:flex items-center justify-center',
            { 'bg-primary': active },
            { 'bg-primary-lightest': !active }
          )}
        >
          <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            xmlns='http://www.w3.org/2000/svg'
            className={classNames(
              { 'fill-white': active },
              { 'fill-primary': !active }
            )}
          >
            <path d='M0 0H13.3333V13.3333H0V0ZM16.6667 0H30V13.3333H16.6667V0ZM0 16.6667H13.3333V30H0V16.6667ZM25 16.6667H21.6667V21.6667H16.6667V25H21.6667V30H25V25H30V21.6667H25V16.6667Z' />
          </svg>
        </div>

        <div className='hidden md:block bg-gradient-to-b from-white to-primary-lightest w-1 absolute md:left-[52%] lg:left-1/2  top-8 h-[calc(100%_-_3rem)] z-0'></div>
        <motion.div
          className='hidden md:block  w-1 h-[calc(100%_-_3rem)] absolute bg-primary md:left-[52%] lg:left-1/2  top-8  z-10 origin-top	'
          style={{ scaleY: scrollYProgress }}
        />
      </div>
    </>
  );
};

export default Part1;

import { useEffect, useRef, useState } from 'react';
import { useScroll } from 'framer-motion';
import TextAreaPart6 from 'section3/textAreas/TextAreaPart6';
import classNames from 'classnames';

const Part6 = () => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setActive(latest > 0);
    });
  }, [scrollYProgress]);
  return (
    <div ref={ref}>
      <div className='relative grid grid-cols-12 gap-1 pb-8 '>
        <div className='col-span-12 md:col-span-6'>
          <TextAreaPart6 active={active} />
        </div>
        <div className='col-span-12 md:col-start-8 md:col-span-5 mt-6 md:mt-0'>
          <img
            src='./images/s3images/part6pic.png'
            alt='pic'
            className='lg:w-3/4 '
          />
        </div>
        <div
          className={classNames(
            'hidden z-10 absolute left-[48%] -top-4  w-16 h-16 rounded-full md:flex items-center justify-center',
            { 'bg-primary': active },
            { 'bg-primary-lightest': !active }
          )}
        >
          <svg
            width='39'
            height='32'
            viewBox='0 0 39 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={classNames(
              'h-8',
              { 'fill-white': active },
              { 'fill-primary': !active }
            )}
          >
            <path d='M34.5 7.00004H27V3.66671C27 1.81671 25.3313 0.333374 23.25 0.333374H15.75C13.6688 0.333374 12 1.81671 12 3.66671V7.00004H4.5C2.41875 7.00004 0.76875 8.48337 0.76875 10.3334L0.75 28.6667C0.75 30.5167 2.41875 32 4.5 32H34.5C36.5813 32 38.25 30.5167 38.25 28.6667V10.3334C38.25 8.48337 36.5813 7.00004 34.5 7.00004ZM23.25 7.00004H15.75V3.66671H23.25V7.00004Z' />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Part6;

import classNames from 'classnames';

const Areas: React.FC<{ areaNumber: number; setAreaNumber: Function }> = ({
  areaNumber,
  setAreaNumber,
}) => {
  return (
    <div className='font-semibold  lg:text-lg'>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 0,
        })}
        onClick={() => {
          setAreaNumber(0);
        }}
      >
        Learner Summary
      </p>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 1,
        })}
        onClick={() => {
          setAreaNumber(1);
        }}
      >
        Education History
      </p>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 2,
        })}
        onClick={() => {
          setAreaNumber(2);
        }}
      >
        Work Experience
      </p>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 3,
        })}
        onClick={() => {
          setAreaNumber(3);
        }}
      >
        Project Portfolio
      </p>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 4,
        })}
        onClick={() => {
          setAreaNumber(4);
        }}
      >
        Skill Expertise
      </p>
      <p
        className={classNames('border-l-2 pl-4 py-4 cursor-pointer', {
          'text-primary border-primary': areaNumber === 5,
        })}
        onClick={() => {
          setAreaNumber(5);
        }}
      >
        Belong Score
      </p>
    </div>
  );
};

export default Areas;

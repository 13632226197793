import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const cardData = [
  {
    id: 1,
    user: 'Samson Tingbani',
    icon: './images/users/userone.png',
    title: 'Head of IT Renewal RoadMap - DHL Global Forwarding, UAE',
    text: '“Belong has become an enabler of opportunities for recent graduates to gain working experience. During this period of Covid, it has become even more challenging for recent graduates to find work or entrepreneurial opportunities.” ',
  },
  {
    id: 2,
    user: 'Romain Diaz',
    icon: './images/users/usertwo.png',
    title: 'Founder, Satgana',
    text: '“Would recommend Belong to any company looking to embrace the future of work. I was fortunate to work with the various apprentices introduced to us. It has been a truly valuable experience."',
  },
  {
    id: 3,
    user: 'Rodrigo Olmedo',
    icon: './images/users/rodrigo.jpeg',
    title: 'Co-Founder - Uglobally',
    text: '"It was great partnering with Belong for this program! Always good energy from the team and great results came from the projects. We got different perceptions of our product and were able. to explore different markets. This program can help you get better talent from different parts of the world." ',
  },
  {
    id: 4,
    user: 'Chaitali Dutta',
    icon: 'https://res.cloudinary.com/belong/image/upload/v1642362261/uploaded_resources/Chaitali_Dutta_q1j2zm.jpg',
    title: 'Founder - Azuke Finance',
    text: '"Excellent! Loved every minute of it. It felt like I was myself back in the classroom - learning new ways to look at problems. The young minds and their questions revealed a perspective which you tend to lose- with a presence in the same industry for 3 decades and working with mature minds."',
  },
];
const CardSlider = ({ deviceType }) => {
  return (
    <Carousel
      arrows={false}
      swipeable={true}
      draggable={true}
      showDots
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      // customTransition='all .5'
      // transitionDuration={500}
      containerClass='carousel-container'
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={deviceType}
    >
      {cardData.map((data) => {
        return (
          <div
            key={data.id}
            className='rounded my-12 border h-newheight md:h-96 lg:h-80  w-[95%] lg:w-[80%]  mx-auto p-4  md:p-6  bg-gradient-to-b from-white to-primary-lightest '
          >
            <div className='flex space-x-6'>
              <img
                src={data.icon}
                alt='userimg'
                className='w-12 md:w-24 h-12 md:h-24 object-contain rounded-full'
              />
              <div className='text-primary'>
                <p className='font-semibold md:text-xl'>{data.user}</p>
                <p className='text-xs sm:text-base'>{data.title}</p>
              </div>
            </div>
            <div className='mt-6'>
              <p>{data.text}</p>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default CardSlider;

// const Card = () => {
//   return cardData.map((data) => {
//     return (
//       <div
//         key={data.id}
//         className='grid grid-flow-col h-80  rounded my-4 space-y-2 bg-primary-lightest  pb-4 '
//       >
//         <div className='flex space-x-6'>
//           <img src={data.icon} alt='user' />
//           <div className='text-primary'>
//             <p className='font-semibold text-2xl'>{data.user}</p>
//             <p>{data.title}</p>
//           </div>
//         </div>
//         <div>
//           <p>{data.text}</p>
//         </div>
//       </div>
//     );
//   });
// };

// export default Card;

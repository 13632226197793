import React from 'react';
import classNames from 'classnames';

const TextAreaPart1: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <div className='lg:w-4/5'>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        Access hyper customised{' '}
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          Industry Experience Platform{' '}
        </span>
      </p>
      <div className='py-6 lg:py-8 flex items-center'>
        <p
          className={classNames(
            'text-lg  px-4 py-1 lg:py-2 rounded-full',
            {
              'text-primary bg-primary-lightest': !active,
            },
            {
              'bg-primary text-white font-medium': active,
            }
          )}
        >
          Platform Integration & Setup
        </p>
      </div>
      <p
        className={classNames(
          'md:w-[90%] text-lg',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        Learner Experience Platform customised to your requirements with white
        labelling & SSO Integration
      </p>
    </div>
  );
};

export default TextAreaPart1;

const PlacementTracking = () => {
  return (
    <div className='space-y-8'>
      <p className='lg:text-2xl text-primary font-medium pl-4 mr-auto w-auto md:w-1/2 lg:w-auto lg:mt-6'>
        Solution to make your placement process an ideal ideal
      </p>
      <div className='font-medium  lg:text-lg  p-4 space-y-6 rounded-2xl'>
        <div className='flex items-center gap-x-2 '>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Employers’ data and records</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Advanced reports and analytics engine</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Seamless communication suite</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Standardized profile and resume management</p>
        </div>
      </div>
    </div>
  );
};

export default PlacementTracking;

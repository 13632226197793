import * as React from 'react';
import { motion } from 'framer-motion';
import { MobileNavItem } from './MobileNavItem';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const Items = [
  {
    id: '1',
    element: (
      <a
        href='https://belong.education/jobs'
        target='_blank'
        rel='noreferrer noopener'
        className='bg-white rounded-2xl px-2 py-1 flex  mx-auto w-3/4  items-center justify-center'
      >
        Jobs
      </a>
    ),
  },
  {
    id: '2',
    element: (
      <a
        href='https://employers.belong.education'
        target='_blank'
        rel='noreferrer noopener'
        className='bg-white rounded-2xl px-2 py-1 flex  mx-auto w-3/4  items-center justify-center'
      >
        Employer
      </a>
    ),
  },
  {
    id: '3',
    element: (
      <a
        href='https://belong.education'
        target='_blank'
        rel='noreferrer noopener'
        className='bg-white rounded-2xl px-2 py-1 flex  mx-auto w-3/4  items-center justify-center'
      >
        Student
      </a>
    ),
  },
  {
    id: '4',
    element: (
      <a
        href='https://calendly.com/jeeva_belong_education/30min'
        rel='noreferrer noopener'
        target='_blank'
        className='border bg-white  text-primary font-semibold px-6 py-1 rounded-lg'
      >
        Schedule Demo
      </a>
    ),
  },
];

export const MobileNavbar: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <>
    <motion.ul
      variants={variants}
      className={classNames(
        ' text-center mt-4 py-8 relative z-10 w-fit mx-auto ',
        {
          'pointer-events-none': !isOpen,
        }
      )}
    >
      {Items.map((item) => (
        <MobileNavItem key={item.id} element={item.element} />
      ))}
    </motion.ul>
  </>
);

import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

const TextAreaPlacement: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <Element name='placement-part' className='element'>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        Elevate learners’ profiles and get
        <br className='hidden lg:block' />
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          {'   '}
          insights on placement performance{' '}
        </span>
      </p>
      <div className='py-6 lg:py-8 flex items-center'>
        <p
          className={classNames(
            'text-lg  px-4 py-1 lg:py-2 rounded-full',
            {
              'text-primary bg-primary-lightest': !active,
            },
            {
              'bg-primary text-white font-medium': active,
            }
          )}
        >
          Placement Booster
        </p>
      </div>
      <div
        className={classNames(
          'md:w-[90%] text-lg space-y-2',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        <p>With your placement portal manage and track progress</p>
      </div>
    </Element>
  );
};

export default TextAreaPlacement;

import React from 'react';
// import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='flex flex-col space-x-14 lg:flex-row text-dark/60'>
      <a
        href='https://belong.education/jobs'
        target='_blank'
        rel='noreferrer noopener'
        className='hover:text-black'
      >
        Jobs
      </a>
      <a
        href='https://employers.belong.education'
        target='_blank'
        rel='noreferrer noopener'
        className='hover:text-black'
      >
        Employer
      </a>
      <a
        href='https://belong.education'
        target='_blank'
        rel='noreferrer noopener'
        className='hover:text-black'
      >
        Student
      </a>
    </div>
  );
};

export default Navbar;

import React from 'react';

const TextSection6 = () => {
  return (
    <div className='text-center pb-12'>
      <p className='text-textColor-main font-semibold lg:text-3xl text-2xl lg:leading-relaxed mt-8 lg:mt-0'>
        <span className='text-primary'> Belong </span> Institute Consoles
      </p>
      <p className='mt-4 text-xl lg:text-xl text-textColor-lighter lg:w-1/2 text-center lg:mx-auto'>
        Customized and white-labelled placement platform enabling hassle-free
        learner engagement tracking recruitment management{' '}
      </p>
    </div>
  );
};

export default TextSection6;

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const cardData = [
  {
    id: 1,
    partner: 'Asia Career Cell',
    icon: './images/uni.png',
    text: `”The program helps students obtain new skills, build connection, outreach to companies that don't operate in the region. They believe that borders should not be what prevent people from pursuing a better future career and make opportunities accessible.” `,
  },
  {
    id: 2,
    partner: 'James Berneking, Programs Manager',
    icon: './images/uni2.png',
    text: '“I think that it is important to provide access to high-impact learning experiences to all students. Belong was able to provide this through virtual internships. The ability to source global internship placements via the network of Belong was an important value-add for us.”',
  },
  {
    id: 3,
    partner:
      'Stuart Hendry, Course Convenor – University of Cape Town, South Africa',
    icon: './images/institutions/capetown.png',
    text: `”I am so grateful to Belong for its vision and commitment to providing enriching work experiences for students. The team were attentive and completely committed to providing a high-impact experience for their business partners.” `,
  },
  {
    id: 3,
    partner: 'Athena School Of Management - Aditya Singh - Director',
    icon: 'https://universities.belong.education/img/universities/athena.png',
    text: `”They broaden horizons, expose them to new cultures and help them attune to the new remote working normal. They create global experiences through the power of the virtual world to make it available to more aspirants." `,
  },
];
const S7CardSlider = ({ deviceType }) => {
  return (
    <Carousel
      arrows={false}
      swipeable={true}
      draggable={true}
      showDots
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      //   customTransition='all .5'
      //   transitionDuration={500}
      containerClass='carousel-container'
      // removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={deviceType}
      //   dotListClass='custom-dot-list-style'
      // itemClass='carousel-item-padding-0-px'
    >
      {cardData.map((data) => {
        return (
          <div
            key={data.id}
            className='rounded my-10 md:my-16 border h-96 md:h-80 w-[95%] lg:w-[80%]   mx-auto p-4 lg:p-8 bg-gradient-to-b from-white to-primary-lightest'
          >
            <div className='lg:flex items-center   lg:space-x-20'>
              <img
                src={data.icon}
                alt='partnerimg'
                className='h-14 w-40 object-contain'
              />
              <div className='text-primary mt-2 lg:mt-0'>
                <p className='font-semibold '>{data.partner}</p>
              </div>
            </div>
            <div className='mt-2 lg:mt-6'>
              <p>{data.text}</p>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default S7CardSlider;

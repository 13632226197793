import React, { useState } from 'react';
import TextSection6 from './TextSection6';
import Tracking from './Tracking';
import PlacementTracking from './PlacementTracking';
import classNames from 'classnames';

const SectionSix = () => {
  const [isPlacementButton, setIsPlacementButton] = useState(true);
  return (
    <div className='pt-12 lg:pt-20'>
      <TextSection6 />
      {/* buttons */}
      <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-center pb-6 lg:pb-12'>
        <button
          onClick={() => setIsPlacementButton(true)}
          className={classNames(
            'font-manrope text-lg font-medium px-6 py-2 rounded-lg transition ease-in-out duration-500',
            {
              'text-white bg-primary': isPlacementButton,
              'text-primary bg-[#9FDDE2]/40': !isPlacementButton,
            }
          )}
        >
          Placement Booster Portal
        </button>
        <button
          onClick={() => setIsPlacementButton(false)}
          className={classNames(
            'font-manrope text-lg font-medium  px-6 py-2 rounded-lg transition ease-in-out duration-500',
            {
              'text-white bg-primary': !isPlacementButton,
              'text-primary bg-[#9FDDE2]/40': isPlacementButton,
            }
          )}
        >
          Learner Journey Tracker{' '}
        </button>
      </div>
      {isPlacementButton ? (
        <div className='grid grid-cols-12 transition ease-in-out duration-500'>
          <div className='col-span-12 lg:col-start-2 lg:col-span-4  my-auto '>
            <PlacementTracking />
          </div>
          <div className='col-span-12 lg:col-start-7 lg:col-span-6 my-8 sm:my-0 '>
            <img src='./images/laptop1.png' alt='laptop-pic' />
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-12 transition ease-in-out duration-500'>
          <div className='col-span-12 lg:col-start-2 lg:col-span-4  my-auto '>
            <Tracking />
          </div>
          <div className='col-span-12 lg:col-start-7 lg:col-span-6 my-8 sm:my-0 '>
            <img src='./images/laptop2.png' alt='laptop-pic' />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionSix;

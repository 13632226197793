import React from 'react';

const TextSection4 = () => {
  return (
    <div className='text-center pb-6 lg:pb-12'>
      <p className='text-textColor-main font-semibold text-3xl  mt-8 lg:mt-0'>
        Our Employability Solution{' '}
        <span className='text-primary'> To Showcase Your Learners </span>
      </p>
      <p className='mt-4 text-xl text-textColor-lighter'>
        Our skill-first approach of Belong Score & Belong Profile enables
        <br />
        your learners employability journey with +500 employers
      </p>
    </div>
  );
};

export default TextSection4;

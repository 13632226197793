import React from 'react';
import { Link } from 'react-scroll';

const SeeHowBox = () => {
  return (
    <Link
      activeClass='active'
      to='section3'
      spy={true}
      smooth={true}
      offset={-10}
      duration={500}
    >
      <div className='absolute cursor-pointer left-1/4 top-1/3 border bg-primary-lightest  rounded-3xl flex  py-1 px-4'>
        <div className='bg-primary   rounded-full h-10 w-10 flex justify-center items-center'>
          <img src='./images/play.png' alt='play' />
        </div>
        <p className='font-medium text-dark/60  ml-2 text-sm'>
          See how <br /> we work
        </p>
        {/* line */}
        <div className='absolute top-[36%] left-[100%] pointer-events-none hidden'>
          <svg>
            <rect x='1' y='0' width='105' height='8' fill='#BFEFFF' />
            <line
              x1='2'
              y1='4'
              x2='105'
              y2='4'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

export default SeeHowBox;

import { useEffect, useRef, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import TextAreaPart5 from 'section3/textAreas/TextAreaPart5';
import classNames from 'classnames';

const Part5 = () => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setActive(latest > 0);
    });
  }, [scrollYProgress]);
  return (
    <div ref={ref} className='relative grid grid-cols-12 gap-1 pb-20 lg:pb-40'>
      <div className='col-span-12 md:col-span-6'>
        <TextAreaPart5 active={active} />
      </div>
      <div className='col-span-12 md:col-start-8 md:col-span-5 mt-6 md:mt-0'>
        <img
          src='./images/s3images/part5pic.png'
          alt='pic'
          className='lg:w-3/4 '
        />
      </div>
      <div
        className={classNames(
          'hidden z-10 absolute left-[48%] -top-4  w-16 h-16 rounded-full md:flex items-center justify-center',
          { 'bg-primary': active },
          { 'bg-primary-lightest': !active }
        )}
      >
        <svg
          width='37'
          height='37'
          viewBox='0 0 37 37'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames(
            'h-8',
            { 'fill-white': active },
            { 'fill-primary': !active }
          )}
        >
          <path d='M34.542 16.2083H20.792V2.45829C20.792 1.8505 20.5505 1.26761 20.1208 0.83784C19.691 0.408069 19.1081 0.166626 18.5003 0.166626C17.8925 0.166626 17.3096 0.408069 16.8799 0.83784C16.4501 1.26761 16.2087 1.8505 16.2087 2.45829V16.2083H2.45866C1.85087 16.2083 1.26798 16.4497 0.838206 16.8795C0.408435 17.3093 0.166992 17.8922 0.166992 18.5C0.166992 19.1077 0.408435 19.6906 0.838206 20.1204C1.26798 20.5502 1.85087 20.7916 2.45866 20.7916H16.2087V34.5416C16.2087 35.1494 16.4501 35.7323 16.8799 36.1621C17.3096 36.5918 17.8925 36.8333 18.5003 36.8333C19.1081 36.8333 19.691 36.5918 20.1208 36.1621C20.5505 35.7323 20.792 35.1494 20.792 34.5416V20.7916H34.542C35.1498 20.7916 35.7327 20.5502 36.1624 20.1204C36.5922 19.6906 36.8337 19.1077 36.8337 18.5C36.8337 17.8922 36.5922 17.3093 36.1624 16.8795C35.7327 16.4497 35.1498 16.2083 34.542 16.2083Z' />
        </svg>
      </div>
      <div className='hidden md:block bg-gradient-to-b from-white to-primary-lightest w-1 absolute md:left-[52%] lg:left-1/2  top-8 h-[calc(100%_-_3rem)] z-0'></div>
      <motion.div
        className='hidden md:block  w-1  absolute bg-primary md:left-[52%] lg:left-1/2 top-8 h-[calc(100%_-_3rem)] z-10 origin-top	'
        style={{ scaleY: scrollYProgress }}
      />
    </div>
  );
};

export default Part5;

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const domainNames = [
  {
    id: 1,
    domName: 'Cloud Computing',
  },
  {
    id: 2,
    domName: 'Data Science',
  },
  {
    id: 3,
    domName: 'Cyber Security',
  },
  {
    id: 4,
    domName: 'Product Design',
  },
  {
    id: 5,
    domName: 'DevOps',
  },
  {
    id: 6,
    domName: 'Operations (Logistics)',
  },
  {
    id: 7,
    domName: 'Business Analytics',
  },
  {
    id: 8,
    domName: 'Marketing',
  },
  {
    id: 9,
    domName: 'Project Management',
  },
  {
    id: 10,
    domName: 'Business Development',
  },
  {
    id: 11,
    domName: 'Human Resource',
  },
  {
    id: 12,
    domName: 'Finance',
  },
  {
    id: 13,
    domName: 'Operations',
  },
  {
    id: 14,
    domName: 'Business Strategy',
  },
  {
    id: 15,
    domName: 'Product Management',
  },
  {
    id: 16,
    domName: 'Entrepreneurship',
  },
  {
    id: 17,
    domName: 'Full Stack Development',
  },
];
const SliderDomains = ({ deviceType }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      customTransition='all 4s linear'
      transitionDuration={2000}
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable
    >
      {domainNames.map(({ id, domName }) => {
        return (
          <div key={id} className='flex justify-center'>
            <p className='text-center  text-primary border-2 whitespace-nowrap mx-8 rounded-full px-2 md:px-4 py-1 min-w-[200px]'>
              {domName}
            </p>
          </div>
        );
      })}
    </Carousel>
  );
};

export default SliderDomains;

import React from 'react';
import classNames from 'classnames';

const TextAreaPart3: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <div className='lg:w-[90%]'>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        Enable Skill Proof via hand-picked
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          {' '}
          Live Industry Immersion
        </span>
      </p>
      <div className='flex items-center space-x-6'>
        {/* first */}
        <div className='py-6  lg:py-8 '>
          <p
            className={classNames(
              'text-lg  px-4 py-1 lg:py-2 rounded-full',
              {
                'text-primary bg-primary-lightest': !active,
              },
              {
                'bg-primary text-white font-medium': active,
              }
            )}
          >
            Apprenticeship
          </p>
        </div>
        {/* second */}
        <div className='py-6  lg:py-8 '>
          <a
            href='https://www.youngexplorer.belong.education/'
            target='_blank'
            rel='noreferrer noopener'
          >
            <p
              className={classNames(
                'text-lg  px-4 py-1 lg:py-2 rounded-full',
                {
                  'text-primary bg-primary-lightest': !active,
                },
                {
                  'bg-primary text-white font-medium': active,
                }
              )}
            >
              GAP K-12
            </p>
          </a>
        </div>
      </div>

      <p
        className={classNames(
          'md:w-[90%] text-lg',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        Experiential upskilling with expert coaches and skill level evaluation
        via live company project work & domain quiz
      </p>
    </div>
  );
};

export default TextAreaPart3;

import { motion, useCycle } from 'framer-motion';

const BelongScoreBox = () => {
  const [color, cycleColor] = useCycle('#000', '#FFF');
  const [divState, cycleDiv] = useCycle('inActive', 'active');

  const divVariants = {
    inActive: { zIndex: 0 },
    active: { zIndex: 10 },
  };
  const lineVariants = {
    initial: { stroke: '#000' },
    animate: { stroke: color },
  };
  return (
    <motion.div
      variants={divVariants}
      animate={divState}
      whileTap={{ scale: 0.9 }}
      onTap={() => {
        cycleColor();
      }}
      onClick={() => cycleDiv()}
      className='absolute cursor-pointer bg-primary-lightest/5 p-2  rounded-lg w-72 h-32  left-[10%] top-[45%]'
    >
      <p className='text-primary'>
        <span className='font-semibold'>One </span>Belong Score
      </p>
      <div className='flex gap-x-2 font-medium text-xs mt-6'>
        <div className='bg-primary-lightest border rounded-3xl px-2 py-1 '>
          <p>Python</p>
        </div>
        <div className='bg-primary-lightest border rounded-3xl px-2 py-1 '>
          <p>Statistics</p>
        </div>
        <div className='bg-primary-lightest border rounded-3xl px-2 py-1 '>
          <p>Data Analytics</p>
        </div>
      </div>
      <p className='my-2'>
        <span className='font-bold '>150+ </span>Skills Certified
      </p>
      <div>
        {/* blue dot image */}
        <div className='absolute z-20 top-[28%] -right-3 hidden'>
          <img src='./images/blueDot.svg' alt='dot' />
        </div>
        {/* lines */}
        <div className='absolute top-1/3 left-[100%] hidden'>
          <svg>
            <motion.rect x='1' y='0' width='100' height='8' fill='#BFEFFF' />
            <motion.line
              x1='2'
              y1='4'
              x2='122'
              y2='4'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1.5 }}
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default BelongScoreBox;

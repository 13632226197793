import { Link } from 'react-scroll';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomLeftArrow from './CustomLeftArrow';
import CustomRightArrow from './CustomRightArrow';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const scoredata = [
  {
    id: 1,
    type: 'Capstone',
    icon: './images/icons/capstone.svg',
    title: 'Integrate your curriculum with industry led projects',
    text: 'Self-Paced and Company Certified',
    action: (
      <div>
        <Link
          activeClass='active'
          to='part2'
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Know More
        </Link>
      </div>
    ),
  },
  {
    id: 2,
    type: 'GAP for K-12',
    icon: './images/icons/k12.svg',
    title: 'Expose your learners to global micro-projects ',
    text: 'Stand out in college applications',
    action: (
      <a
        target='_blank'
        href='https://www.youngexplorer.belong.education/'
        rel='noreferrer noopener'
      >
        Know More
      </a>
    ),
  },
  {
    id: 3,
    type: 'Placement Booster',
    icon: './images/icons/placement.svg',
    title: 'Track learner placements and manage the process',
    text: 'Exclusive portal connecting students and employers',
    // action: 'Know More',
    action: (
      <div>
        <Link
          activeClass='active'
          to='placement-part'
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          Know More
        </Link>
      </div>
    ),
  },
  {
    id: 4,
    type: 'Apprenticeship',
    icon: './images/icons/apprenticeship.svg',
    title:
      'Integrate your learner journey with our recruiter led work experience',
    text: 'Last mile to your learner journey',
    action: (
      <a
        target='_blank'
        href='https://calendly.com/jeeva_belong_education/30min'
        rel='noreferrer noopener'
      >
        Schedule Demo
      </a>
    ),
  },
];

const Card = () => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={true}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      autoPlay={false}
      autoPlaySpeed={500}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      customTransition='all 1s linear'
      transitionDuration={500}
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable
      removeArrowOnDeviceType={['tablet', 'mobile']}
    >
      {scoredata.map((data) => {
        return (
          <div
            key={data.id}
            className='relative group grid mx-6 grid-flow-col rounded-md my-4  bg-primary-lightest hover:bg-dark pb-4 '
          >
            <div className='absolute pl-2 lg:pl-4 py-1 font-medium w-48 lg:w-72 top-0 left-0  border group-hover:border-0 rounded-r-2xl bg-gradient-to-r from-white to-primary-lightest group-hover:to-primary flex gap-x-2'>
              <img src={data.icon} alt='logo' className='h-8' />
              <p className='ml-2 lg:ml-4 text-sm lg:text-lg flex items-center '>
                {data.type}
              </p>
            </div>
            <div className='p-4 lg:p-6 lg:w-[80%] mt-10 grid '>
              <p className='group-hover:text-white font-medium lg:text-lg'>
                {data.title}
              </p>
              <p className='group-hover:text-white  my-8 text-sm md:text-base'>
                {data.text}
              </p>
              <p className='text-primary text-sm md:text-base cursor-pointer underline underline-offset-2'>
                {data.action}
              </p>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
  // return scoredata.map((data: any) => {
  //   return (
  //     <div
  //       key={data.id}
  //       className='relative group grid grid-flow-col rounded-md my-4  bg-primary-lightest hover:bg-dark pb-4 '
  //     >
  //       <div className='absolute pl-2 lg:pl-4 py-1 font-medium w-48 lg:w-72 top-0 left-0  border group-hover:border-0 rounded-r-2xl bg-gradient-to-r from-white to-primary-lightest group-hover:to-primary flex gap-x-2'>
  //         <img src={data.icon} alt='logo' className='h-8' />
  //         <p className='ml-2 lg:ml-4 text-sm lg:text-lg flex items-center '>
  //           {data.type}
  //         </p>
  //       </div>
  //       <div className='p-4 lg:p-6 lg:w-[80%] mt-10 grid '>
  //         <p className='group-hover:text-white font-medium lg:text-lg'>
  //           {data.title}
  //         </p>
  //         <p className='group-hover:text-white  my-8 text-sm md:text-base'>
  //           {data.text}
  //         </p>
  //         <p className='text-primary text-sm md:text-base cursor-pointer underline underline-offset-2'>
  //           {data.action}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // });
};

export default Card;

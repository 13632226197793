import { useEffect, useRef, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import TextAreaPart4 from 'section3/textAreas/TextAreaPart4';
import classNames from 'classnames';

const Part4 = () => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setActive(latest > 0);
    });
  }, [scrollYProgress]);
  return (
    <div ref={ref} className='relative grid grid-cols-12 gap-1 pb-20 lg:pb-40'>
      <div className='col-span-12 md:col-span-5 order-2 md:order-1 mt-6 md:mt-0'>
        <img
          src='./images/s3images/part4pic.png'
          alt='pic'
          className='lg:w-3/4 mx-auto'
        />
      </div>
      <div className='col-span-12 md:col-start-8 md:col-span-5 order-1 md:order-2'>
        <TextAreaPart4 active={active} />
      </div>
      <div
        className={classNames(
          'hidden z-10 absolute left-[48%] -top-4  w-16 h-16 rounded-full md:flex items-center justify-center',
          { 'bg-primary': active },
          { 'bg-primary-lightest': !active }
        )}
      >
        <svg
          width='42'
          height='42'
          viewBox='0 0 42 42'
          xmlns='http://www.w3.org/2000/svg'
          className={classNames(
            'h-8',
            { 'fill-white': active },
            { 'fill-primary': !active }
          )}
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M18.1719 1.19976C18.8799 0.492708 19.8272 0.0766669 20.8268 0.0337188C21.8265 -0.00922927 22.8059 0.324035 23.5719 0.96776L23.8279 1.20176L27.6279 4.99976H32.9999C34.0087 4.99994 34.9802 5.3813 35.7198 6.06743C36.4593 6.75356 36.9123 7.69379 36.9879 8.69976L36.9999 8.99976V14.3718L40.7999 18.1718C41.5075 18.8798 41.9239 19.8275 41.9669 20.8276C42.0098 21.8277 41.6762 22.8076 41.0319 23.5738L40.7979 23.8278L36.9979 27.6278V32.9998C36.9983 34.0089 36.6171 34.9809 35.9309 35.7209C35.2448 36.4608 34.3042 36.9141 33.2979 36.9898L32.9999 36.9998H27.6299L23.8299 40.7998C23.1219 41.5074 22.1742 41.9238 21.1741 41.9667C20.174 42.0097 19.1941 41.6761 18.4279 41.0318L18.1739 40.7998L14.3739 36.9998H8.99994C7.99079 37.0001 7.01881 36.6189 6.27884 35.9328C5.53888 35.2466 5.08563 34.3061 5.00994 33.2998L4.99994 32.9998V27.6278L1.19994 23.8278C0.492329 23.1197 0.0759359 22.172 0.0329837 21.1719C-0.00996861 20.1718 0.323642 19.1919 0.96794 18.4258L1.19994 18.1718L4.99994 14.3718V8.99976C5.00013 7.99095 5.38148 7.01947 6.06761 6.27993C6.75375 5.54039 7.69397 5.08742 8.69994 5.01176L8.99994 4.99976H14.3719L18.1719 1.19976ZM27.1579 14.9658L18.6699 23.4538L15.1339 19.9178C14.7587 19.5427 14.2498 19.3322 13.7192 19.3324C13.1887 19.3325 12.68 19.5435 12.3049 19.9188C11.9299 20.294 11.7193 20.8029 11.7195 21.3335C11.7197 21.864 11.9307 22.3727 12.3059 22.7478L17.1139 27.5558C17.3182 27.7602 17.5608 27.9223 17.8278 28.0329C18.0948 28.1435 18.3809 28.2005 18.6699 28.2005C18.9589 28.2005 19.2451 28.1435 19.5121 28.0329C19.7791 27.9223 20.0216 27.7602 20.2259 27.5558L29.9859 17.7938C30.3503 17.4166 30.5518 16.9113 30.5473 16.387C30.5427 15.8626 30.3324 15.3609 29.9616 14.9901C29.5908 14.6193 29.0891 14.409 28.5647 14.4044C28.0403 14.3999 27.5351 14.6014 27.1579 14.9658Z'
          />
        </svg>
      </div>
      <div className='hidden md:block bg-gradient-to-b from-white to-primary-lightest w-1 absolute md:left-[52%] lg:left-1/2  top-8 h-[calc(100%_-_3rem)] z-0'></div>
      <motion.div
        className='hidden md:block  w-1  absolute bg-primary md:left-[52%] lg:left-1/2 top-8 h-[calc(100%_-_3rem)] z-10 origin-top	'
        style={{ scaleY: scrollYProgress }}
      />
    </div>
  );
};

export default Part4;

import React from 'react';

const BottomSection = () => {
  return (
    <div className='py-12 -mx-4 md:-mx-20'>
      <div className='bg-primary-lightest py-6 text-center'>
        <p className='font-semibold lg:text-3xl text-2xl mb-8'>
          Prepare Your Learners For The Real World{' '}
        </p>
        <p className='text-lg px-2 mb-8'>
          Help Them Access Work From Home Experience &{' '}
          <br className='hidden md:block' />
          Remote Upskilling Programs
        </p>
        <div className='mt-8 mb-2'>
          <a
            href='https://calendly.com/jeeva_belong_education/30min'
            rel='noreferrer noopener'
            target='_blank'
            className='text-white bg-primary font-semibold  px-6 py-3  rounded-lg  lg:text-xl  shadow'
          >
            Schedule Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default BottomSection;

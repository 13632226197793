import React from 'react';
import classNames from 'classnames';

const TextAreaPart4: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <div>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        Enhance Employment Profile & <br className='hidden lg:block' />
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          {'   '}
          Unlock Jobs & Internships
        </span>
      </p>
      <div className='py-6 lg:py-8 flex items-center'>
        <p
          className={classNames(
            'text-lg  px-4 py-1 lg:py-2 rounded-full',
            {
              'text-primary bg-primary-lightest': !active,
            },
            {
              'bg-primary text-white font-medium': active,
            }
          )}
        >
          Belong Score
        </p>
      </div>
      <div
        className={classNames(
          'md:w-[90%] text-lg space-y-2',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        <p>
          Learner builds Belong Score and an industry standard profile by
          achieving experiential milestones
        </p>
        <p>Recognized by 500+ recruiters</p>
      </div>
    </div>
  );
};

export default TextAreaPart4;

import React from 'react';
import Card from './Card';
import SliderDomains from './SliderDomains';
import TextSection from './TextSection';

const SectionTwo = () => {
  return (
    <div>
      <TextSection />
      <div className='my-12 lg:px-12'>
        <Card />
      </div>
      <p className='my-12 text-center text-lg lg:text-xl text-textColor-lighter '>
        We offer range of products for your learner's holistic industry
        <br className='hidden lg:block' />
        upskilling and employability needs across
        <span className='font-bold'> 10+ domains</span>
      </p>
      <div className='-mx-4 md:-mx-20 '>
        <SliderDomains />
      </div>
    </div>
  );
};

export default SectionTwo;

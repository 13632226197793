import React from 'react';

const ImageLayout: React.FC<{ areaNumber: number }> = ({ areaNumber }) => {
  return (
    <div className='rounded-xl px-3 py-2 bg-white w-4/5 mx-auto border space-y-2'>
      <img
        src='./images/s4images/summary.png'
        alt='summary-img'
        className={`${
          areaNumber !== 0 ? 'grayscale' : 'scale-110 drop-shadow-md'
        }`}
      />
      <div className='flex gap-x-1 '>
        <div>
          <img
            src='./images/s4images/skills.png'
            alt='skill-img'
            className={`${
              areaNumber !== 4 ? 'grayscale' : 'scale-110 drop-shadow-md'
            }`}
          />
          <img
            src='./images/s4images/apprenticeship.png'
            alt='apprentice-img'
            className='grayscale'
          />
        </div>
        <div className='space-y-2'>
          <img
            src='./images/s4images/education.png'
            alt='education-img'
            className={`${
              areaNumber !== 1 ? 'grayscale' : 'scale-110 drop-shadow-md'
            }`}
          />
          <img
            src='./images/s4images/experience.png'
            alt='experience-img'
            className={`${
              areaNumber !== 2 ? 'grayscale' : 'scale-110 drop-shadow-md'
            }`}
          />
          <img
            src='./images/s4images/portfolio.png'
            alt='portfolio-mg'
            className={`${
              areaNumber !== 3 ? 'grayscale' : 'scale-110 drop-shadow-md'
            }`}
          />
        </div>
      </div>
      <img
        src='./images/s4images/belongscore.png'
        alt='score-img'
        className={`${
          areaNumber !== 5 ? 'grayscale' : 'scale-110 drop-shadow-md'
        }`}
      />
    </div>
  );
};

export default ImageLayout;

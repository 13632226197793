import React from 'react';
import classNames from 'classnames';

const TextAreaPart5: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <div className='lg:w-4/5'>
      <p
        className={classNames(
          'lg:text-3xl text-2xl',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-main': active }
        )}
      >
        Support with
        <span
          className={classNames(
            'font-semibold',
            { 'text-primary-lighter': !active },
            { 'text-primary': active }
          )}
        >
          {' '}
          interview prep & profile building{' '}
        </span>
      </p>
      <div className='py-6  lg:py-8 flex items-center '>
        <p
          className={classNames(
            'text-lg  px-4 py-1 lg:py-2 rounded-full',
            {
              'text-primary bg-primary-lightest': !active,
            },
            {
              'bg-primary text-white font-medium': active,
            }
          )}
        >
          Value Add-Ons{' '}
        </p>
      </div>
      <p
        className={classNames(
          'md:w-[90%] text-lg',
          { 'text-textColor-lightest': !active },
          { 'text-textColor-lighter': active }
        )}
      >
        Interview Prep, Profile & LinkedIn Building, Placement Readiness
        Bootcamps
      </p>
    </div>
  );
};

export default TextAreaPart5;

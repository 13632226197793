import { useEffect, useRef, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import TextAreaPart2 from 'section3/textAreas/TextAreaPart2';
// import { Element } from 'react-scroll';
import classNames from 'classnames';

const Part2 = () => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end end', 'start start'],
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setActive(latest > 0);
    });
  }, [scrollYProgress]);

  return (
    <div ref={ref}>
      <div className='relative grid grid-cols-12 gap-1 pb-20 lg:pb-40'>
        <div className='col-span-12 md:col-span-5  order-2 md:order-1 mt-6 md:mt-0 '>
          <img
            src='./images/s3images/part2pic.png'
            alt='pic'
            className='lg:w-3/4 mx-auto'
          />
        </div>
        <div className='col-span-12 md:col-start-8 md:col-span-5  order-1 md:order-2'>
          <TextAreaPart2 active={active} />
        </div>
        <div
          className={classNames(
            'hidden z-10 absolute left-[48%] -top-4  w-16 h-16 rounded-full md:flex items-center justify-center',
            { 'bg-primary': active },
            { 'bg-primary-lightest': !active }
          )}
        >
          <svg
            width='46'
            height='46'
            viewBox='0 0 46 46'
            xmlns='http://www.w3.org/2000/svg'
            className={classNames(
              'h-8',
              { 'fill-white': active },
              { 'fill-primary': !active }
            )}
          >
            <path d='M0 38.472V0H42.1053V16.8315H37.8947V4.809H4.21053V33.663H21.0526V38.472H0ZM15.7895 30.0562V8.41574L30.5263 19.236L15.7895 30.0562ZM33.3684 45.6855L32.7368 42.0787C32.3158 41.8783 31.9214 41.6684 31.5537 41.4487C31.1846 41.2275 30.807 40.9566 30.4211 40.636L27.3684 41.718L25.2632 37.6304L27.6842 35.2259C27.614 34.6648 27.5789 34.1439 27.5789 33.663C27.5789 33.1821 27.614 32.6611 27.6842 32.1001L25.2632 29.6956L27.3684 25.6079L30.4211 26.6899C30.807 26.3693 31.1846 26.0984 31.5537 25.8772C31.9214 25.6576 32.3158 25.4476 32.7368 25.2472L33.3684 21.6405H37.5789L38.2105 25.2472C38.6316 25.4476 39.0267 25.668 39.3958 25.9085C39.7635 26.1489 40.1404 26.4495 40.5263 26.8102L43.5789 25.6079L45.6842 29.8158L43.2632 32.2203C43.3333 32.7012 43.3684 33.2021 43.3684 33.7231C43.3684 34.2441 43.3333 34.745 43.2632 35.2259L45.6842 37.6304L43.5789 41.718L40.5263 40.636C40.1404 40.9566 39.7635 41.2275 39.3958 41.4487C39.0267 41.6684 38.6316 41.8783 38.2105 42.0787L37.5789 45.6855H33.3684ZM35.4737 38.472C36.6316 38.472 37.6232 38.0015 38.4484 37.0605C39.2723 36.118 39.6842 34.9854 39.6842 33.663C39.6842 32.3405 39.2723 31.208 38.4484 30.2654C37.6232 29.3245 36.6316 28.854 35.4737 28.854C34.3158 28.854 33.3249 29.3245 32.5011 30.2654C31.6758 31.208 31.2632 32.3405 31.2632 33.663C31.2632 34.9854 31.6758 36.118 32.5011 37.0605C33.3249 38.0015 34.3158 38.472 35.4737 38.472Z' />
          </svg>
        </div>
        <div className='hidden md:block bg-gradient-to-b from-white to-primary-lightest w-1 absolute md:left-[52%] lg:left-1/2  top-8 h-[calc(100%_-_3rem)] z-0'></div>
        <motion.div
          className='hidden md:block  w-1 absolute bg-primary md:left-[52%] lg:left-1/2 top-8 h-[calc(100%_-_3rem)] z-10 origin-top	'
          style={{ scaleY: scrollYProgress }}
        />
      </div>
    </div>
  );
};

export default Part2;

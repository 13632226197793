import { motion, useCycle } from 'framer-motion';

const CapstoneProjectBox = () => {
  const [color, cycleColor] = useCycle('#000', '#FFF');
  const [divState, cycleDiv] = useCycle('inActive', 'active');

  const divVariants = {
    inActive: { zIndex: 0 },
    active: { zIndex: 30 },
  };
  const lineVariants = {
    initial: { stroke: '#000' },
    animate: { stroke: color },
  };
  return (
    <motion.div
      variants={divVariants}
      animate={divState}
      whileTap={{ scale: 0.9 }}
      onTap={() => {
        cycleColor();
      }}
      onClick={() => cycleDiv()}
      className='absolute cursor-pointer  bg-primary-lightest/5 p-2   rounded-lg w-64 h-36  left-[70%] top-1/3'
    >
      <p>
        <span className='font-semibold'>200+ </span> Self- Paced <br />
        Capstone Projects
      </p>
      <div className='flex gap-x-1 font-medium text-xs mt-6 text-primary'>
        <div className='bg-white border border-primary rounded-3xl  px-1 py-1 flex gap-x-1'>
          <p>Marketing</p>
        </div>
        <div className='bg-white border border-primary rounded-3xl  px-1 py-1 flex gap-x-1'>
          <p>Data Science</p>
        </div>
        <div className='bg-white border border-primary rounded-3xl  px-2 py-1 flex gap-x-1'>
          <p>UI/UX</p>
        </div>
      </div>
      <p className='my-2 text-primary'>
        <span className='font-bold '>10+ </span>Industry Domains
      </p>
      <div>
        {/* lines */}
        <div className='absolute top-1 right-[31%] pointer-events-none hidden'>
          <svg>
            <motion.rect x='8' y='14' width='117' height='8' fill='#BFEFFF' />
            <motion.line
              x1='2'
              y1='18'
              x2='117'
              y2='18'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
            <motion.rect x='0' y='-4' width='8' height='20' fill='#BFEFFF' />
            <motion.line
              x1='2'
              y1='2'
              x2='2'
              y2='12'
              stroke='#000'
              stroke-dasharray='4'
              stroke-width='2'
              variants={lineVariants}
              initial='initial'
              animate='animate'
              transition={{ duration: 1 }}
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};

export default CapstoneProjectBox;

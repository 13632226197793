import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

const TextAreaPart2: React.FC<{ active: Boolean }> = ({ active }) => {
  return (
    <Element name='part2' className='element'>
      <div>
        <p
          className={classNames(
            'lg:text-3xl text-2xl',
            { 'text-textColor-lightest': !active },
            { 'text-textColor-main': active }
          )}
        >
          Add-on Unlimited <br />
          <span
            className={classNames(
              'font-semibold',
              { 'text-primary-lighter': !active },
              { 'text-primary': active }
            )}
          >
            {' '}
            Capstone Certifications{' '}
          </span>{' '}
        </p>
        <div className='py-6  lg:py-8 flex items-center'>
          <p
            className={classNames(
              'text-lg  px-4 py-1 lg:py-2 rounded-full',
              {
                'text-primary bg-primary-lightest': !active,
              },
              {
                'bg-primary text-white font-medium': active,
              }
            )}
          >
            Capstone{' '}
          </p>
        </div>
        <p
          className={classNames(
            'md:w-[90%] text-lg',
            { 'text-textColor-lightest': !active },
            { 'text-textColor-lighter': active }
          )}
        >
          Self-Paced & Industry Certified projects mapped to domains & skills in
          your curriculum
        </p>
      </div>
    </Element>
  );
};

export default TextAreaPart2;

const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className='absolute left-0  flex items-center  cursor-pointer transition-all ease-in-out duration-300'
    >
      <svg
        width='15'
        height='26'
        viewBox='0 0 15 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.3984 25.3998L0.698437 13.6998C0.587326 13.5887 0.509549 13.4776 0.465104 13.3665C0.42066 13.2554 0.398438 13.1332 0.398438 12.9998C0.398438 12.8665 0.42066 12.7443 0.465104 12.6332C0.509549 12.522 0.587326 12.4109 0.698437 12.2998L12.3984 0.599821C12.6429 0.355377 12.954 0.233154 13.3318 0.233154C13.7095 0.233154 14.0207 0.355377 14.2651 0.599821C14.5318 0.866488 14.6651 1.18315 14.6651 1.54982C14.6651 1.91649 14.5318 2.23315 14.2651 2.49982L3.7651 12.9998L14.2651 23.4998C14.5318 23.7665 14.6595 24.0832 14.6484 24.4498C14.6373 24.8165 14.5095 25.122 14.2651 25.3665C13.9984 25.6332 13.6818 25.7665 13.3151 25.7665C12.9484 25.7665 12.6429 25.6443 12.3984 25.3998Z'
          fill='#505050'
        />
      </svg>
    </button>
  );
};

export default CustomLeftArrow;

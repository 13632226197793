import React from 'react';
import { Element } from 'react-scroll';

import Part1 from './parts/Part1';
import Part2 from './parts/Part2';
import Part3 from './parts/Part3';
import Part4 from './parts/Part4';
import Part5 from './parts/Part5';
import Part6 from './parts/Part6';

import TextSection3 from './TextSection3';
import PlacementPart from './parts/PlacementPart';

const SectionThree = () => {
  return (
    <Element name='section3' className=' pt-6 lg:pt-16'>
      <TextSection3 />
      <div className='my-12'>
        <Part1 />
        <Part2 />
        <Part3 />
        <Part4 />
        <Part5 />
        <PlacementPart />
        <Part6 />
      </div>
    </Element>
  );
};

export default SectionThree;

import { motion, useCycle } from 'framer-motion';

const UserImageBox = () => {
  const [color, cycleColor] = useCycle('#000', '#FFF');
  const [divState, cycleDiv] = useCycle('inActive', 'active');

  const divVariants = {
    inActive: { zIndex: 20 },
    active: { zIndex: 20 },
  };
  const lineVariants = {
    initial: { stroke: '#000' },
    animate: { stroke: color },
  };
  return (
    <motion.div
      variants={divVariants}
      animate={divState}
      whileTap={{ scale: 0.9 }}
      onTap={() => {
        cycleColor();
      }}
      onClick={() => cycleDiv()}
      className='absolute  cursor-pointer  top-[42%] left-[44%] '
    >
      <img
        src='./images/users/user.webp'
        alt='logo'
        className='hidden lg:block w-32 z-20'
      />
      {/* blue dot */}
      <div className='absolute z-20 top-0 left-[40%] hidden'>
        <img src='./images/blueDot.svg' alt='dot' />
      </div>
      {/* lines */}
      <div className='absolute bottom-[30%] left-[46%] pointer-events-none hidden'>
        <svg>
          <motion.rect x='1' y='14' width='8' height='50' fill='#BFEFFF' />
          <motion.line
            x1='4'
            y1='16'
            x2='4'
            y2='56'
            stroke='#000'
            stroke-dasharray='4'
            stroke-width='2'
            variants={lineVariants}
            initial='initial'
            animate='animate'
            transition={{ duration: 1 }}
          />
          <motion.rect x='8' y='14' width='70' height='8' fill='#BFEFFF' />
          <motion.line
            x1='2'
            y1='18'
            x2='82'
            y2='18'
            stroke='#000'
            stroke-dasharray='4'
            stroke-width='2'
            variants={lineVariants}
            initial='initial'
            animate='animate'
            transition={{ duration: 1 }}
          />
          <motion.rect x='72' y='-4' width='8' height='20' fill='#BFEFFF' />
          <motion.line
            x1='76'
            y1='2'
            x2='76'
            y2='12'
            stroke='#000'
            stroke-dasharray='4'
            stroke-width='2'
            variants={lineVariants}
            initial='initial'
            animate='animate'
            transition={{ duration: 1 }}
          />
        </svg>
      </div>
      {/* <div className='absolute -top-16 left-[105%] bg-red-300 h-6 w-2'></div> */}
    </motion.div>
  );
};

export default UserImageBox;

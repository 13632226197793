const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className='absolute  right-0   flex justify-end items-center  cursor-pointer transition-all ease-in-out duration-300'
    >
      <svg
        width='15'
        height='26'
        viewBox='0 0 15 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.60156 25.3998L14.3016 13.6998C14.4127 13.5887 14.4905 13.4776 14.5349 13.3665C14.5793 13.2554 14.6016 13.1332 14.6016 12.9998C14.6016 12.8665 14.5793 12.7443 14.5349 12.6332C14.4905 12.522 14.4127 12.4109 14.3016 12.2998L2.60156 0.599821C2.35712 0.355377 2.04601 0.233154 1.66823 0.233154C1.29045 0.233154 0.979342 0.355377 0.734897 0.599821C0.46823 0.866488 0.334897 1.18315 0.334897 1.54982C0.334897 1.91649 0.46823 2.23315 0.734897 2.49982L11.2349 12.9998L0.734897 23.4998C0.46823 23.7665 0.340452 24.0832 0.351563 24.4498C0.362675 24.8165 0.490452 25.122 0.734897 25.3665C1.00156 25.6332 1.31823 25.7665 1.6849 25.7665C2.05156 25.7665 2.35712 25.6443 2.60156 25.3998Z'
          fill='#505050'
        />
      </svg>
    </button>
  );
};

export default CustomLeftArrow;

import React from 'react';
import S7CardSlider from './S7CardSlider';

const SectionSeven = () => {
  return (
    <div className='pt-12'>
      <p className='font-semibold lg:text-3xl text-2xl text-center'>
        Hear From
        <span className='text-primary'> Our Institution Partners </span>
      </p>
      <S7CardSlider />
    </div>
  );
};

export default SectionSeven;

import { FaLinkedinIn } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { BsTwitter } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='lg:mt-16 mt-8 bg-gray-50 -mx-4 p-4 md:p-0 md:bg-white'>
      <div className='border-t-2 border-textColor'></div>
      <div className='lg:px-12 grid grid-cols-12 mb-10'>
        <div className='lg:col-span-5 col-span-12'>
          <div className='flex space-x-4 items-center my-6'>
            <img
              className='object-contain w-16'
              alt='logo'
              src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
            />
            <p className='lg:text-3xl text-xl lg:font-semibold font-medium text-primary '>
              Belong
            </p>
          </div>
          <p className='mb-4'>India’s largest industry experience platform</p>
          <p>
            Belong Education is an employability platform that facilitates
            interaction between students and employers through
            industry-integrated work experience opportunities
          </p>
        </div>
        <div className='lg:col-span-7 col-span-12 flex lg:justify-around justify-between lg:mt-16 mt-8 lg:font-medium font-normal text-lg lg:leading-modifiedHeight leading-9'>
          <div>
            <div>
              <a
                href='https://belong.education/about-us'
                target='_blank'
                rel='noopener noreferrer'
              >
                About Us
              </a>
            </div>
            <div>
              <a
                href='https://belong.education/contact'
                target='_blank'
                rel='noopener noreferrer'
              >
                Contact Us
              </a>
            </div>
            <div className='flex space-x-4 text-primary lg:text-3xl  text-xl mt-6'>
              <div>
                <a
                  href='https://www.linkedin.com/school/ingeniousfaces/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <div>
                <a
                  href='https://www.instagram.com/ingeniousfaces/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <RiInstagramFill />
                </a>
              </div>
              <div>
                <a
                  href='https://twitter.com/IngeniousFaces'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <BsTwitter />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div>
              <a
                href='https://belong.education/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy
              </a>
            </div>
            <div>
              <a
                href='https://belong.education/terms-&-conditions'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='border-t-2 border-textColor'></div>
      <div className='lg:text-lg lg:my-8 my-4'>
        <p>
          &copy; Belong. All Rights Reserved.{' '}
          <span>{new Date().getFullYear()}</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;

// const Footer = () => {
//   return (
//     <div className='bg-[#E9E9E9] -mx-4 md:-mx-20 p-4 md:px-8 text-[#767676]'>
//       <div className='lg:px-12 md:px-8 grid grid-cols-12 mb-2'>
//         <div className='md:col-span-5 col-span-12'>
//           <div className='flex space-x-4 items-center my-6'>
//             <img
//               className='object-contain h-12 w-12 md:h-16 md:w-16'
//               src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
//               alt='logo'
//             />
//             <p className='lg:text-3xl text-xl lg:font-semibold font-medium text-primary '>
//               Belong
//             </p>
//           </div>
//           <div className='flex space-x-4  lg:text-3xl  text-xl mt-6 md:mt-20'>
//             <div>
//               <a
//                 href='https://www.instagram.com/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img src='/images/social/insta.svg' alt='insta' />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://www.facebook.com/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img src='/images/social/facebook.svg' alt='facebook' />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://twitter.com/IngeniousFaces'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img src='/images/social/twitter.svg' alt='twitter' />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://www.linkedin.com/school/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img src='/images/social/linkedin.svg' alt='linkedin' />
//               </a>
//             </div>
//           </div>
//         </div>
//         <div className='md:col-span-7 col-span-12  lg:mt-16 mt-8 flex lg:justify-around justify-between  text-lg lg:leading-modifiedHeight leading-9'>
//           <div>
//             <p className='font-semibold mb-4 text-lg'>Company</p>
//             <div>
//               <a
//                 href='https://belong.education/about-us'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 About Us
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://belong.education/contact'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 Contact Us
//               </a>
//             </div>
//           </div>
//           <div>
//             <p className='font-semibold mb-4 text-lg'>Policy</p>
//             <div>
//               <a
//                 href='https://belong.education/privacy-policy'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 Privacy
//               </a>
//             </div>
//             <a
//               href='https://belong.education/terms-&-conditions'
//               target='_blank'
//               rel='noopener noreferrer'
//               className='text-sm md:text-base'
//             >
//               Terms & Conditions
//             </a>
//           </div>
//         </div>
//       </div>
//       <p className='md:pl-8 lg:pl-12'>Terms & Conditions and Privacy Policy</p>
//       <div className='lg:text-lg md:pl-8  lg:pl-12'>
//         <p>
//           &copy; Belong. All Rights Reserved.{' '}
//           <span>{new Date().getFullYear()}</span>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;

// const Footer = () => {
//   return (
//     <div className='bg-[#E9E9E9] -mx-4 md:-mx-20 p-4 md:px-8 text-[#767676]'>
//       <div className='lg:px-12 md:px-8 grid grid-cols-12 mb-2'>
//         <div className='md:col-span-5 col-span-12'>
//           <div className='flex space-x-4 items-center my-6'>
//             <img
//               className='object-contain h-12 w-12 md:h-16 md:w-16'
//               src='https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
//               alt='logo'
//             />
//             <p className='lg:text-3xl text-xl lg:font-semibold font-medium text-primary '>
//               Belong
//             </p>
//           </div>
//           <div className='flex space-x-4  lg:text-3xl  text-xl mt-6 md:mt-20 '>
//             <div>
//               <a
//                 href='https://www.instagram.com/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img
//                   src='https://res.cloudinary.com/belong/image/upload/v1680784536/logos/linkedinf_tpdgki.png'
//                   alt='insta'
//                 />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://www.facebook.com/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img
//                   src='https://res.cloudinary.com/belong/image/upload/v1680784542/logos/facebookf_lkbsos.png'
//                   alt='facebook'
//                 />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://twitter.com/IngeniousFaces'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img
//                   src='https://res.cloudinary.com/belong/image/upload/v1680784510/logos/twitterf_zap6j1.png'
//                   alt='twitter'
//                 />
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://www.linkedin.com/school/ingeniousfaces/'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <img
//                   src='https://res.cloudinary.com/belong/image/upload/v1680784536/logos/linkedinf_tpdgki.png'
//                   alt='linkedin'
//                 />
//               </a>
//             </div>
//           </div>
//         </div>
//         <div className='md:col-span-7 col-span-12  lg:mt-16 mt-8 flex lg:justify-around justify-between  text-lg leading-9'>
//           <div>
//             <p className='font-semibold mb-4 text-lg'>Company</p>
//             <div>
//               <a
//                 href='https://belong.education/about-us'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 About Us
//               </a>
//             </div>
//             <div>
//               <a
//                 href='https://belong.education/contact'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 Contact Us
//               </a>
//             </div>
//           </div>
//           <div>
//             <p className='font-semibold mb-4 text-lg'>Policy</p>

//             <div>
//               <a
//                 href='https://belong.education/privacy-policy'
//                 target='_blank'
//                 rel='noopener noreferrer'
//                 className='text-sm md:text-base'
//               >
//                 Privacy
//               </a>
//             </div>
//             <a
//               href='https://belong.education/terms-&-conditions'
//               target='_blank'
//               rel='noopener noreferrer'
//               className='text-sm md:text-base'
//             >
//               Terms & Conditions
//             </a>
//           </div>
//         </div>
//       </div>
//       <p className='md:pl-8 lg:pl-12 md:mt-6 '>
//         Terms & Conditions and Privacy Policy
//       </p>
//       <div className='lg:text-lg md:pl-8  lg:pl-12'>
//         <p>
//           &copy; Belong. All Rights Reserved.{' '}
//           <span>{new Date().getFullYear()}</span>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import SliderInstitutes from './SilderInstitutes';

const Institutes = () => {
  return (
    <div className='grid lg:grid-flow-col grid-cols-12  lg:py-6'>
      <div className='col-span-12 lg:col-span-3 text-lg flex items-center'>
        <p className='text-textColor-lightest font-normal mb-4 lg:mb-0 '>
          Trusted by 100+ Institutions
        </p>
      </div>
      <div className='col-span-12 lg:col-span-9 '>
        <SliderInstitutes />
      </div>
    </div>
  );
};

export default Institutes;

import Header from './Header';
import React from 'react';
import SectionTwo from 'section2/SectionTwo';
import SectionThree from 'section3/SectionThree';
import SectionFive from 'section5/SectionFive';
import SectionSeven from 'section7/SectionSeven';
import SectionSix from 'section6/SectionSix';
import BottomSection from 'BottomSection';
import OurAchievements from 'OurAchievements';
import SectionFour from 'section4/SectionFour';
import SectionOne from './section1/SectionOne';
import Footer from 'Footer';

function App() {
  return (
    <div className='px-4 md:px-8 lg:px-16 space-y-4 font-poppins overflow-x-hidden'>
      <Header />
      <SectionOne />
      <OurAchievements />
      <SectionTwo />
      <SectionThree />
      <SectionFive />
      <SectionSix />
      <SectionFour />
      <SectionSeven />
      <BottomSection />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import CardSlider from './sliderCards/CardSlider';
import SliderEmployers from './SliderEmployers';

const SectionFive = () => {
  return (
    <div className='lg:pt-24'>
      <p className='font-semibold lg:text-3xl text-2xl text-center'>
        Our <span className='text-primary'> Recruiter Ecosystem</span>
      </p>
      <div className='-mx-4 md:-mx-20 '>
        <SliderEmployers />
      </div>
      <CardSlider />
    </div>
  );
};

export default SectionFive;

import { useInterval } from 'hooks/useInterval';
import React, { useState } from 'react';
import Areas from './Areas';
import ImageLayout from './ImageLayout';
import TextSection4 from './TextSection4';

const SectionFour = () => {
  const [areaNumber, setAreaNumber] = useState(0);

  useInterval(() => {
    setAreaNumber((areaNumber) => (areaNumber + 1) % 6);
  }, 3000);
  return (
    <div className='hidden lg:block pt-6 lg:pt-16'>
      <TextSection4 />
      <div className='grid grid-cols-12'>
        <div className='col-span-3 w-96 mx-auto  my-auto '>
          <Areas areaNumber={areaNumber} setAreaNumber={setAreaNumber} />
        </div>
        <div className='col-span-9 my-8'>
          <ImageLayout areaNumber={areaNumber} />
        </div>
      </div>
      <p className='mt-4 text-2xl text-textColor-lighter text-center'>
        Get your learners a push start to their career through professional
        avenues
      </p>
      <div className='flex justify-center mt-8'>
        <a
          href='https://calendly.com/jeeva_belong_education/30min'
          rel='noreferrer noopener'
          target='_blank'
          className='text-white bg-primary font-semibold  px-6 py-3 rounded-lg  lg:text-xl  shadow'
        >
          Schedule Demo
        </a>
      </div>
    </div>
  );
};

export default SectionFour;

import React from 'react';

const TextSection3 = () => {
  return (
    <div className='text-center pb-6 lg:pb-12'>
      <p className='text-textColor-main font-semibold lg:text-3xl text-2xl lg:leading-relaxed mt-8 lg:mt-0'>
        You Choose, We Curate,
        <span className='text-primary'> Learner's Excel </span>
      </p>
      <p className='mt-4 text-lg lg:text-xl text-textColor-lighter'>
        We curate your learner's industry experience & engagement in parallel to
        <br />
        your curriculum, cohort schedules and domain requirements{' '}
      </p>
    </div>
  );
};

export default TextSection3;

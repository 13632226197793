import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const employersLogos = [
  {
    id: 1,
    logo: './images/institutions/one.png',
  },
  {
    id: 2,
    logo: './images/institutions/two.png',
  },
  {
    id: 3,
    logo: './images/institutions/three.png',
  },
  {
    id: 4,
    logo: './images/institutions/four.png',
  },
  {
    id: 5,
    logo: './images/institutions/five.png',
  },
  {
    id: 6,
    logo: './images/institutions/six.png',
  },
  {
    id: 7,
    logo: './images/institutions/seven.png',
  },
  {
    id: 8,
    logo: './images/institutions/eight.png',
  },
  {
    id: 9,
    logo: './images/institutions/nine.png',
  },
  {
    id: 10,
    logo: './images/institutions/ten.png',
  },
  {
    id: 11,
    logo: './images/institutions/eleven.png',
  },
];

const SliderInstitutes = () => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      customTransition='all 4s linear'
      transitionDuration={2000}
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 5,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 4,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable
    >
      {employersLogos.map(({ id, logo }) => {
        return (
          <div key={id} className='flex  justify-center md:mx-2 items-center '>
            <img src={logo} alt='company-logo' />
          </div>
        );
      })}
    </Carousel>
  );
};

export default SliderInstitutes;

import React from 'react';

const TextSection = () => {
  return (
    <div className='text-center'>
      <p className='text-textColor-main font-semibold  lg:text-3xl text-2xl lg:leading-relaxed mt-8'>
        Accelerate Learner Employability with
        <br className='hidden md:block' />
        <span className='text-primary'>
          {' '}
          Hyper Customised Industry Immersions
        </span>
      </p>
    </div>
  );
};

export default TextSection;

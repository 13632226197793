const Tracking = () => {
  return (
    <div className='space-y-8'>
      <p className='lg:text-2xl text-primary font-medium pl-4 mr-auto w-auto md:w-1/2 lg:w-auto lg:mt-6'>
        All inclusive platform to manage and track student’s capstone and
        apprenticeship performance
      </p>
      <div className='font-medium  lg:text-lg  p-4 space-y-6 rounded-2xl'>
        <div className='flex items-center gap-x-2 '>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Experience Journey & Schedule</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Upskilling Data & Statistics</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Attendance & Engagement Reports</p>
        </div>
        <div className='flex items-center gap-x-2'>
          <img src='./images/Pin.svg' alt='logo' className='w-10 h-10' />
          <p>Assessment Score & Feedback</p>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
